<template>
  <div id="addresslist">
    <v-flex xs12>
      <v-btn icon color="black" style="float:right" @click="$emit('close')">
        <v-icon medium>mdi-close</v-icon>
      </v-btn>
      <div class="text pt-2"> Change Delivery Address</div>
      
      <v-divider class="mt-4 mb-4" color="black"></v-divider>
      <div v-if="shipping" class="ml-2">
        <p class="text"> {{shipping.name}} : 
          <span :class="shipping_promo === 0 ? '': 'line-thru'"> {{ addComma(shipping.tarif)}} </span>
          IDR 
          <span v-if="shipping_promo"> ({{ addComma(shipping.tarif - shipping_promo) }} IDR)</span>
        </p>
      </div>
      
      <v-flex xs12 sm12 md12  :class="isMobile ? 'mt-2 mb-1' : ''"  v-for="(item, index) in address" :key="index">
        <v-layout wrap :class="isMobile ? 'item' : 'pl-0'" @click="editPrimary(item)">
          <v-flex xs12 sm12 md12 class="pt-1">
            <div class="address-btn mt-m30">
              <v-btn v-if="item.priority === 'primary'" small icon color="green" :title="$t('address.primary-address')">
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn v-else small icon color="grey" title="set primary">
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md8>
            <div class="detail-item">
              <span class="title"> {{ item.penerima }} </span> 
              <p class="subtitle mb-0">{{ item.alamat_lengkap }}</p>
              <p class="subtitle mb-0">{{ item.district }}</p>
              <p class="subtitle mb-0">{{ item.city }} {{ item.kode_pos }}</p>
              <p class="subtitle mb-0">{{ item.province }}</p>
              <p class="subtitle mb-0">Indonesia</p>
              <p class="subtitle">{{ item.hp_penerima }}</p>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'AddressList',
     props: {
      shipping_promo: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapState([
        'isMobile',
        'token',
        'cart',
        'shipping',
      ])
    },
    data () {
      return {
        address: [],
        oldAddress: null,
        message: '',
        message2: ''
      }
    },
    methods: {
      editPrimary (item) {
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.patch('/api/v1/member/alamat_priority/' + item.id_alamat)
          .then(response => {
            // this.$store.commit('setSnackbar', true)
            // this.$store.commit('setText', response.data.message)
            this.message = response.data.message

            this.getDelivery(response)
          })
          .catch(error => {
            this.errorHandling(error)
            // this.$store.commit('setIsLoading', false)
          })
      },
      getDelivery () {
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }
        let body = {
          products: this.cart.map(item => {
            return {
              // sku_product: item.size.sku_product,
              // qty: item.quantity
              product_code: item.data.product_code,
              sku_product: this.getSkuProduct(item),
              qty: item.quantity
            }
          })
        }
        // axios.post('/api/v1/orders/delivery/', body)
        // /api/v1/orders/cart_new
        axios.post('/api/v1/orders/cart', body)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.message)
            this.message2 = response.data.message

            if (response.data.data.jenis_paket && Array.isArray(response.data.data.jenis_paket)) {
              this.$store.commit('setShipping', response.data.data.jenis_paket[0])

              // const shipping = response.data.data.jenis_paket
              // const defaultShipping = shipping.find(item => { return item.provider_code === "SICEPAT" && item.service === "REG"})
              
              // this.$store.commit('setShipping', defaultShipping || response.data.data.jenis_paket[0])
              this.$store.commit('setShippingOptions', response.data.data.jenis_paket)
            } else {
              this.$store.commit('setShipping', response.data.data.jenis_paket)
            }

            this.$store.commit('setDelivery', response.data.data.alamat_penerima)
            this.getAddress()
          })
          .catch(error => {
            // this.editPrimary(this.oldAddress[0])
            this.errorHandling(error)
            this.$router.push({name: 'Cart'})
          })
      },
      getAddress () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.get('/api/v1/member/alamat')
          .then(response => {
            this.address = response.data.data
            this.oldAddress = response.data.data.filter(item => item.priority === 'primary');
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      getSkuProduct (item) {
        if (item.isBundling) {
          return item.bundling.map(value => {
            return {
              sku_product: value.variant.sku_product,
              qty : value.qty
            }
          })
        } else {
          return item.size.sku_product
        }
      }
    }, 
    mounted () {
      this.getAddress()
    }
  }
</script>

<style scoped>
  #addresslist {
    background-color: #fff;
    /* border: 2px solid #fff; */
    /* border-radius: 10px; */
    padding: 10px;
    max-height: 600px;
    overflow-y: auto;
  }

  .item {
    padding: 10px;
  }

  .detail-item {
    color: #000;
  }

  .text {
    color: #000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
    font-style: normal !important;
  }

  .title {
    color: #000;
    font-weight: 900;
    font-size: 0.9rem !important;
  }

  .subtitle {
    color: #000;
    font-size: 0.8rem !important;
  }

  .subtitle-gray {
    color: #808080;
    font-size: 0.8rem !important;
  }

  .bordered {
    border: 1px solid #000;
  }

  .btn-text {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .address-btn {
    float: right;
  }

  .mt-m30 {
    margin-bottom: -30px;
  }

  .line-thru {
    text-decoration: line-through;
  }

</style>
